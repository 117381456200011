import { CardContent, Card, Typography, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import CloudIcon from '@material-ui/icons/Cloud';
import HelpIcon from '@material-ui/icons/Help';
import PersonIcon from '@material-ui/icons/Person';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import './Features.css';

export default function Features(): ReactElement {
    return (
        <div className="features">
            <div className="features-title">Why choose us?</div>
            <div className="features-container">
                <Grid container justify="center" spacing={5}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className="features-card">
                            <CardContent>
                                <div className="card-icon-container">
                                    <CloudIcon className="card-icon" />
                                </div>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Cloud based
                                </Typography>
                                <Typography gutterBottom variant="body2" component="h2">
                                    No need for downloading anything, it is fully online. Just make an account, and use
                                    our app. It is that simple. Make your account <a href="#">here</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className="features-card">
                            <CardContent>
                                <div className="card-icon-container">
                                    <HelpIcon className="card-icon" />
                                </div>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Customer Support
                                </Typography>
                                <Typography gutterBottom variant="body2" component="h2">
                                    You have our support 24/7. Go <a href="#">here</a> to send us your questions.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className="features-card">
                            <CardContent>
                                <div className="card-icon-container">
                                    <TouchAppIcon className="card-icon" />
                                </div>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Easy to use
                                </Typography>
                                <Typography gutterBottom variant="body2" component="h2">
                                    It is very easy and intuitive to use. Click <a href="#">here</a> to check our
                                    tutorial video and start your jurney with us.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className="features-card">
                            <CardContent>
                                <div className="card-icon-container">
                                    <MonetizationOnIcon className="card-icon" />
                                </div>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Affordable
                                </Typography>
                                <Typography gutterBottom variant="body2" component="h2">
                                    See our packages <a href="#">here</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className="features-card">
                            <CardContent>
                                <div className="card-icon-container">
                                    <PersonIcon className="card-icon" />
                                </div>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Lorem ipsum
                                </Typography>
                                <Typography gutterBottom variant="body2" component="h2">
                                    Lorem ipsum dollor sit amet
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
