import React, { ReactElement, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import './Hero.css';

export default function Hero(): ReactElement {
    const [activeSlide, setActiveSlide] = useState<number>(0);

    const changeActiveState = (): void => {
        console.log(activeSlide);
        if (activeSlide >= 3) {
            setActiveSlide(0);
        } else {
            setActiveSlide(activeSlide + 1);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => changeActiveState(), 6000);
        return () => clearTimeout(timer);
    });

    return (
        <>
            <div className="nlk-hero">
                <div className={activeSlide == 0 ? 'slide active' : 'slide inactive'} />
                <div className={activeSlide == 1 ? 'slide active' : 'slide inactive'} />
                <div className={activeSlide == 2 ? 'slide active' : 'slide inactive'} />
                <div className={activeSlide == 3 ? 'slide active' : 'slide inactive'} />
                <div className="nlk-layer" />
                <div className="nlk-hero-text">
                    <Typography className="hero-h4" variant="h4" color="inherit">
                        ARMATURE Solutions
                    </Typography>
                    <Typography className="hero-h5" variant="h5" color="inherit">
                        -Bending and shaping metal frameworks
                    </Typography>
                    <Typography className="hero-h5" variant="h5" color="inherit">
                        -Authomatic calculation of bar length required
                    </Typography>
                    <Typography className="hero-h5" variant="h5" color="inherit">
                        - One click of a button
                    </Typography>
                </div>
            </div>
        </>
    );
}
