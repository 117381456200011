import { CssBaseline } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import GranitLanding from './GranitLanding/GranitLanding';
import Navbar from './Navbar/Navbar';
import ArmatureLanding from './NelkenLanding/ArmatureLanding';

export default function App(): ReactElement {
    return (
        <>
            <CssBaseline />
            <Navbar />
            <Routes>
                <Route key={'GranitShort'} path={'/c/1'} element={<GranitLanding />} />
                <Route key={'Granit'} path={'/c/granit'} element={<GranitLanding />} />
                <Route key={'armit_home'} element={<ArmatureLanding />} />
            </Routes>
            <Contact />
            <Footer />
        </>
    );
}
