import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, AppBar, Toolbar } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CropIcon from '@material-ui/icons/Crop';
import './Navbar.css';
import { Link } from 'react-router-dom';

export default function Navbar(): ReactElement {
    const useStyles = makeStyles((theme) => ({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'rgb(20, 20, 20)',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
    }));

    const classes = useStyles();

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className="nlk-navbar">
                    <Link className="link" to={'/armit_home'}>
                        <IconButton edge="start" color="inherit">
                            <CropIcon style={{ width: 50, height: 50, marginRight: 20 }} />
                            <Typography variant="h5" color="inherit" style={{ display: 'inline', margin: 'auto' }}>
                                Armature Solutions
                            </Typography>
                        </IconButton>
                    </Link>
                </Toolbar>
            </AppBar>
        </>
    );
}
