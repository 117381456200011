import { Card, CardActions, Button, CardContent, CardHeader, CardMedia, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

type Props = {
    title: string;
    img: string;
    description: string;
    hasBtn: boolean;
    btnFunc(): void;
    btnText?: string;
};

export default function CardElementGranit(props: Props): ReactElement {
    return (
        <>
            <Card>
                <CardHeader title={props.title} />
                <CardMedia component="img" height="194" image={props.img} alt="Paella dish" />
                <CardContent>
                    <Typography variant="body2" color="textSecondary">
                        {props.description}
                    </Typography>
                </CardContent>
                {props.hasBtn ? (
                    <CardActions disableSpacing>
                        <Link className="link" to={'/nelken'}>
                            <Button
                                onClick={props.btnFunc}
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: 'rgba(2,136,209,255)' }}
                            >
                                {props.btnText}
                            </Button>
                        </Link>
                    </CardActions>
                ) : (
                    <></>
                )}
            </Card>
        </>
    );
}
