import React, { ReactElement } from 'react';
import Hero from './Hero/Hero';
import Services from './Services/Services';
import './ArmatureLanding.css';
import Cooperators from './Cooperators/Cooperators';
import Features from './Features/Features';

export default function ArmatureLanding(): ReactElement {
    return (
        <>
            <Hero />
            <Services />
            <Features />
            <Cooperators />
        </>
    );
}
