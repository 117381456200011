import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import granit_logo from '../../assets/img/granit_logo-1.png';
import './Cooperators.css';
import { Link } from 'react-router-dom';

export default function Cooperators(): ReactElement {
    return (
        <div className="coop">
            <hr />
            <div className="coop-title">Pridruzite se kompanijama koje su nam dale svoje poverenje</div>
            <div className="coop-container">
                <Link className="link" to={'/c/granit'}>
                    <IconButton color="inherit">
                        <img src={granit_logo} alt="logo" style={{ width: 70, height: 70 }} />
                        <Typography variant="h4" color="inherit" style={{ display: 'inline', margin: 'auto' }}>
                            Granit
                        </Typography>
                    </IconButton>
                </Link>
            </div>
        </div>
    );
}
