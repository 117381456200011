import React, { ReactElement } from 'react';
import { Grid, Typography } from '@material-ui/core';
import armature_img from '../../assets/img/armature.jpg';
import building_materials from '../../assets/img/building_materials.jpg';
import CardElementGranit from '../../CardElement/CardElementGranit';
import './Services.css';

export default function Services(): ReactElement {
    return (
        <>
            <div className="grt-services">
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography className="services-h5" variant="h5" color="inherit">
                            Servisi koje pruzamo
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="card">
                            <CardElementGranit
                                hasBtn={true}
                                btnFunc={() => {
                                    console.log('func');
                                }}
                                btnText="Saznajte vise"
                                description="Savijanje, prodaja i transport armatura"
                                title="Armature"
                                img={armature_img}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="card">
                            <CardElementGranit
                                hasBtn={false}
                                btnFunc={() => {
                                    console.log('no btn');
                                }}
                                description="Prodaja i transport materijala"
                                title="Materijal"
                                img={building_materials}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
