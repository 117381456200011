import React, { ReactElement } from 'react';
import { Link, Typography } from '@material-ui/core';
import truck_img from '../../assets/img/truck.jpg';
import './Hero.css';

export default function Hero(): ReactElement {
    return (
        <>
            <div className="grt-hero">
                <div className="grt-hero-text">
                    <Typography className="hero-h4" variant="h4" color="inherit">
                        <Link
                            style={{ textDecoration: 'none' }}
                            href="http://www.granit-kragujevac.com"
                            target="_blank"
                        >
                            GRANIT D.O.O. KRAGUJEVAC
                        </Link>
                    </Typography>
                    <Typography className="hero-h5" variant="h5" color="inherit">
                        Gradjevinska firma
                    </Typography>
                </div>
                <div className="grt-hero-img">
                    <div className="grt-layer"></div>
                    <img src={truck_img} alt="truck" />
                </div>
            </div>
        </>
    );
}
