import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import './Contact.css';

export default function Contact(): ReactElement {
    return (
        <>
            <div className="nlk-contact">
                <div className="container">
                    <Typography variant="h5">Kontakt</Typography>
                    <Typography variant="body1">Garsije Lorke 2g/26, 11060 Beograd</Typography>
                    <Typography variant="body1">+381643744383</Typography>
                    <Typography variant="body1">nine@nelken.ltd</Typography>
                </div>
            </div>
        </>
    );
}
