import React, { ReactElement } from 'react';
import Hero from './Hero/Hero';
import Services from './Services/Services';
import './GranitLanding.css';
import ContactUs from './ContactUs/ContactUs';

export default function GranitLanding(): ReactElement {
    return (
        <>
            <Hero />
            <Services />
            <ContactUs />
        </>
    );
}
