import { Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import './ContactUs.css';

export default function ContactUs(): ReactElement {
    return (
        <>
            <Grid container spacing={5} className="contactus-container">
                <Grid item xs={12}>
                    <Typography variant="h4" component="h5">
                        Kontaktirajte nas
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" component="h6" className="contactus-subtitle">
                        Osnovni podaci
                    </Typography>
                    <div className="contactus-text-container">
                        <Typography variant="body1" component="h6">
                            <span className="bold-text">
                                <HomeOutlinedIcon className="contactus-icon" /> Adresa:{' '}
                            </span>{' '}
                            Mlavska bb, Kragujevac
                        </Typography>
                        <Typography variant="body1" component="h6">
                            <span className="bold-text">
                                <PhoneIcon className="contactus-icon" /> Telefon:{' '}
                            </span>{' '}
                            034/370-350
                        </Typography>
                        <Typography variant="body1" component="h6">
                            <span className="bold-text">
                                <PhoneIcon className="contactus-icon" /> Fax:{' '}
                            </span>{' '}
                            034/305-920
                        </Typography>
                        <Typography variant="body1" component="h6">
                            <span className="bold-text">
                                <MailOutlineIcon className="contactus-icon" /> Email:{' '}
                            </span>{' '}
                            granit.doo@gmail.com
                        </Typography>
                        <Typography variant="body1" component="h6">
                            <span className="bold-text">
                                <AccessTimeIcon className="contactus-icon" /> Radno vreme:{' '}
                            </span>{' '}
                            07-16h
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" component="h6" className="contactus-subtitle">
                        Armature
                    </Typography>
                    <Typography variant="body1" component="h6">
                        <span className="bold-text">
                            <MailOutlineIcon className="contactus-icon" /> Email:{' '}
                        </span>{' '}
                        granit.armatura@gmail.com
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
