import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import './Footer.css';

export default function Footer(): ReactElement {
    return (
        <>
            <div className="nlk-footer">
                <Typography variant="h6">&copy; 2022 nelken.eu</Typography>
            </div>
        </>
    );
}
